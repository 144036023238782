<template>
  <b-table
    :data="data.data"
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    :range-before="5"
    :range-after="5"
    paginated
    backend-pagination
    pagination-position="both"
    :total="data.count"
    :per-page="data.perpage"
    @page-change="onPageChange"
    :current-page.sync="data.page"
    aria-next-label="Weiter"
    aria-previous-label="Zurück"
    aria-page-label=" Seite "
    aria-current-label="Aktuelle Seite"
    backend-sorting
    :default-sort="[data.sortField, data.sortOrder]"
    @sort="onSort"
    icon-pack="icm"
    :mobile-cards="false"
  >
    <template slot="top-left">
      <div>
        <b-field grouped>
          <b-field class="control has-icons-right">
            <input class="input" placeholder="Suche" v-model="searchphrase" v-on:keyup.enter="list()" />
            <span class="icon is-right" @click="list()" id="searchbutton">
              <i class="icm icm-search"></i>
            </span>
          </b-field>
          <b-field class="control">
            <a :to="add" @click="add()" type="is-link" class="navbar-link is-arrowless"><b-icon icon="plus1"></b-icon></a>
          </b-field>
        </b-field>
      </div>
    </template>

    <b-table-column v-slot="props" field="Wine.id" label="Id" numeric sortable>
      {{ $utilities.padLeftWithNull(props.row.id) }}
    </b-table-column>

    <b-table-column v-slot="props" label="Bild">
      <img :src="getImage(props.row.image)" width="18" height="18" alt="" />
    </b-table-column>

    <b-table-column v-slot="props" field="producer_name_de" label="Produzent" sortable>
      {{ props.row.producer_name_de }}
    </b-table-column>

    <b-table-column v-slot="props" field="Wine.name_de" label="Name" sortable>
      {{ props.row.name_de }}
    </b-table-column>

    <b-table-column v-slot="props" field="Wine.jahr" label="Jahrgang" numeric sortable>
      {{ props.row.jahr == 0 ? "n.V" : props.row.jahr }}
    </b-table-column>

    <b-table-column v-slot="props" field="anzahl_article" label="Artikel" numeric sortable>
      {{ props.row.anzahl_article }}
    </b-table-column>

    <b-table-column v-slot="props" field="typ" label="T" numeric sortable>
      <span :title="getTyp(props.row.typ)">{{ props.row.typ.toUpperCase() }}</span>
    </b-table-column>

    <b-table-column v-slot="props" field="anzahl_ratings" label="Bew." numeric sortable>
      {{ props.row.anzahl_ratings }}
    </b-table-column>

    <b-table-column v-slot="props" label="A" class="iconlink" field="link1" header-class="iconlink">
      <a title="Ansehen" @click.prevent="view(props.row.id)">
        <b-icon icon="find_in_page"></b-icon>
      </a>
    </b-table-column>

    <b-table-column v-slot="props" label="B" class="editlink" field="link2" header-class="iconlink">
      <a title="Bearbeiten" @click.prevent="edit(props.row.id)">
        <b-icon icon="square-edit-outline"></b-icon>
      </a>
    </b-table-column>

    <b-table-column v-slot="props" label="K" class="editlink" field="link3" header-class="iconlink">
      <a title="Kopieren" @click.prevent="copy1(props.row.id, props.row.name_de, props.row.jahr)">
        <b-icon icon="add_box"></b-icon>
      </a>
    </b-table-column>
    <b-table-column v-slot="props" label="L" class="editlink" header-class="iconlink">
      <a title="Löschen" @click.prevent="delete1(props.row.id, props.row.name_de)">
        <b-icon icon="delete_forever"></b-icon>
      </a>
    </b-table-column>
  </b-table>
</template>

<script>
import View from "./winesview.vue";
import Form from "./winesform.vue";
import CopyForm from "./winescopyform.vue";
export default {
  name: "winesList",
  data() {
    return {
      apiControllerName: "wines",
      loading: {},
      data: {
        count: 0,
        perpage: 50,
        data: [],
        page: 1,
        order: { field: "name", direction: "ASC" },
      },
      error: "",
      regionList: [],
      producerList: [],
      subtitle: "",
      isLoading: true,
      isReady: false,
      searchphrase: "",
      modal: false,
    };
  },

  computed: {
    listYears: function () {
      var l = [];
      l[0] = "n/a";
      for (var i = new Date().getFullYear(); i >= 1900; i--) {
        l[i] = i;
      }
      return l;
    },
  },
  created: function () {
    //console.log('hurz');
  },

  mounted: function () {
    this.$store.commit("setHeadline", {
      subheadline: "Weine",
    });

    this.list();
    this.isReady = true;
    this.getRegionList();
    this.getProducerList();
  },
  methods: {
    getRegionList: function () {
      if (this.regionList.length > 10) {
        return true;
      }

      var t = this;
      t.$http({
        url: "/" + t.apiControllerName + "/get-region-list/",
        method: "get",
      })
        .then(function (resp) {
          t.regionList = resp.data.data;

          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (typeof error.response != "undefined" && typeof error.response.data.message != "undefined") {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
        });
      return false;
    },
    getProducerList: function () {
      if (this.producerList.length > 10) {
        return true;
      }

      var t = this;
      t.$http({
        url: "/" + t.apiControllerName + "/get-producer-list/",
        method: "get",
      })
        .then(function (resp) {
          t.producerList = resp.data.data;

          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (typeof error.response != "undefined" && typeof error.response.data.message != "undefined") {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
        });
      return false;
    },
    list: async function (data = null) {
      var t = this;
      t.isLoading = true;
      t.$http
        .post("/" + t.apiControllerName, {
          page: t.data.page,
          search: t.searchphrase,
          order: t.data.order,
        })
        .then(function (resp) {
          t.data = resp.data.data;
          t.isLoading = false;
          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (typeof error.response != "undefined" && typeof error.response.data.message != "undefined") {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
          t.isLoading = false;
        });
    },
    onPageChange(page) {
      this.data.page = page;
      this.list();
    },
    /*
     * Handle sort event
     */
    onSort(field, direction) {
      //console.log(field + ' » ' + direction)

      this.data.order = { field, direction };
      this.list();
    },
    view: function (id) {
      var t = this;
      //console.log("view " + id)
      t.$http({
        url: "/" + t.apiControllerName + "/view/" + id,
        method: "get",
      })
        .then(function (resp) {
          //console.log( resp.data.data);

          t.modal = t.$buefy.modal.open({
            parent: t,
            component: View,
            hasModalCard: false,
            width: 1250,
            props: { data: resp.data.data },
          });

          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (typeof error.response != "undefined" && typeof error.response.data.message != "undefined") {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
        });
    },
    edit: function (id) {
      var t = this;

      //this.$router.replace('/users/edit/' + id)

      t.$http({
        url: "/" + t.apiControllerName + "/view/" + id,
        method: "get",
      })
        .then(function (resp) {
          //console.log( resp.data.data);

          t.modal = t.$buefy.modal.open({
            parent: t,
            component: Form,
            hasModalCard: false,
            width: 1250,
            props: { pdata: resp.data.data },
          });

          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (typeof error.response != "undefined" && typeof error.response.data.message != "undefined") {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
        });
    },

    add: function () {
      var t = this;
      var pdata = {
        name_de: "",
        name_en: "",
        producer_id: "",
        region_id: "",
        ean: "",
        artnr_produzent: "",
        typ: "r",
      };

      t.modal = t.$buefy.modal.open({
        parent: t,
        component: Form,
        hasModalCard: false,
        scroll: "keep",
        props: { pdata: pdata },
      });
    },

    copy1: function (id) {
      var t = this;

      //this.$router.replace('/users/edit/' + id)

      t.$http({
        url: "/" + t.apiControllerName + "/view/" + id,
        method: "get",
      })
        .then(function (resp) {
          //console.log( resp.data.data);

          t.modal = t.$buefy.modal.open({
            parent: t,
            component: CopyForm,
            hasModalCard: false,
            width: 1250,
            props: { pdata: resp.data.data },
          });

          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (typeof error.response != "undefined" && typeof error.response.data.message != "undefined") {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
        });
    },

    delete1: function (id, name) {
      var t = this;
      t.modal = this.$buefy.dialog.confirm({
        title: "Bitte bestätigen!",
        message: "Wollen Sie Datensatz #" + id + ' "' + name + '" wirklich löschen?',
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.delete2(id),
      });
    },
    delete2: function (id) {
      //console.log('delete '+id);
      var t = this;
      this.$http({
        url: "/" + t.apiControllerName + "/delete/" + id,
        method: "get",
      })
        .then(function (resp) {
          t.list(resp.data.data);
          t.modal.close();
          //console.log(resp.data)
          return true;
        })
        .catch(function (error) {
          //console.log('Shithappens')
          //console.log(error)
          if (typeof error.response != "undefined" && typeof error.response.data.message != "undefined") {
            this.$buefy.toast.open({
              message: error.response.data.message,
              type: "is-danger",
              duration: 5000,
            });
          } else {
            this.$buefy.toast.open({
              message: "Server (API) nicht erreichbar. Bitte versuchen Sie es später erneut.",
              type: "is-danger",
              duration: 5000,
            });
          }
        });
    },
    async save(formdata) {
      //console.log('speichern3')
      this.isLoading = true;
      var t = this;
      t.$http
        .post("/" + t.apiControllerName + "/save", { data: formdata })
        .then(function (resp) {
          t.isLoading = false;

          t.list(resp.data.data);
          t.modal.close();
          return true;
        })
        .catch(function (error) {
          //console.log('Shithappens')
          if (typeof error.response != "undefined" && typeof error.response.data.message != "undefined") {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
          t.isLoading = false;
          return false;
        });
      return false;
    },

    async copy2(formdata) {
      //console.log(formdata)
      this.isLoading = true;
      var t = this;
      t.$http
        .post("/" + t.apiControllerName + "/copy", { data: formdata })
        .then(function (resp) {
          t.isLoading = false;

          t.list(resp.data.data);
          t.modal.close();
          return true;
        })
        .catch(function (error) {
          //console.log('Shithappens')
          if (typeof error.response != "undefined" && typeof error.response.data.message != "undefined") {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
          t.isLoading = false;
          return false;
        });
      return false;
    },
    getProducer: function (id) {
      for (var i = 0; i <= this.producerList.length; i++) {
        if (this.producerList[i].id == id) {
          return this.producerList[i].name_de;
        }
      }
      return "unbekannt";
    },

    getRegion: function (id) {
      for (var i = 0; i <= this.regionList.length; i++) {
        if (this.regionList[i].id == id) {
          return this.regionList[i].name_de;
        }
      }
      return "unbekannt";
    },
    getTyp: function (id) {
      for (var i = 0; i <= this.$utilities.typ.length; i++) {
        if (this.$utilities.typ[i].id == id) {
          return this.$utilities.typ[i].name;
        }
      }
      return "unbekannt";
    },

    getImage(image) {
      if (image != false) {
        return this.$store.getters.config.page.imageBaseUrl+"/" + image;
      } else {
        return this.$store.getters.config.page.imageBaseUrl+"/images/nopic.jpg";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#searchbutton {
  pointer-events: all !important;
  cursor: pointer !important;
}
</style>
