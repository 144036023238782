var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.typeOfAction))])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Kundennummer"}},[_vm._v(" #"+_vm._s(_vm.$utilities.padLeftWithNull(_vm.data.id))+" ")])],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Name deutsch","type":{ 'is-danger': _vm.$v.data.name_de.$error },"message":{
              'Bitte geben Sie einen deutschen Namen an.':
                _vm.submitted && _vm.$v.data.name_de.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.name_de.$error },model:{value:(_vm.$v.data.name_de.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.name_de, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.name_de.$model"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Name englisch","type":{ 'is-danger': _vm.$v.data.name_en.$error },"message":{
              'Bitte geben Sie einen englischen Namen an.':
                _vm.submitted && _vm.$v.data.name_en.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.name_en.$error },model:{value:(_vm.$v.data.name_en.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.name_en, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.name_en.$model"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Land","type":{ 'is-danger': _vm.$v.data.country_id.$error },"message":{
              'Bitte wählen Sie ein Land.':
                _vm.submitted && _vm.$v.data.country_id.$error
            }}},[_c('b-select',{class:{ is_danger: _vm.$v.data.country_id.$error },staticStyle:{"width":"inherit"},attrs:{"placeholder":"Bitte wählen Sie ein Land"},model:{value:(_vm.$v.data.country_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.country_id, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.country_id.$model"}},_vm._l((_vm.$parent.$parent.countryList),function(name,key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(" "+_vm._s(name)+" ")])}),0)],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Sortierziffer","type":{ 'is-danger': _vm.$v.data.sortno.$error },"message":{
              'Bitte geben Sie eine Sortierziffer an.':
                _vm.submitted && _vm.$v.data.sortno.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.sortno.$error },model:{value:(_vm.$v.data.sortno.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.sortno, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.sortno.$model"}})],1)],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"column"},[_c('b-button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("Schließen")]),_vm._v("   "),_c('b-button',{staticClass:"button",attrs:{"type":"is-info","native-type":"submit","icon-left":"cloud_upload"}},[_vm._v("Speichern")])],1)])]),_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading,"can-cancel":false},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }