<template>
  <div class="card">
    <form method="post" @submit.prevent="save">
      <div class="card-header">
        <h1 class="card-header-title">{{ typeOfAction }}</h1>
      </div>
      <div class="card-content">
        <div class="columns view-columns is-multiline">
          <div class="column  is-half">
            <b-field
              label="Neuer Jahrgang"
              :type="{ 'is-danger': $v.data.jahr.$error }"
              :message="{
                'Bitte wählen Sie ein Jahr.': submitted && $v.data.jahr.$error
              }"
            >
              <b-select
                placeholder="Bitte wählen Sie ein Jahr"
                v-model.trim="$v.data.jahr.$model"
                :class="{ is_danger: $v.data.jahr.$error }"
                style="width:inherit"
              >
                <option
                  v-for="option in yearList"
                  :value="option.key"
                  :key="option.key"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-half">
            <label>Artikel kopieren</label>
            <div v-for="(a, k) in articles" :key="k">
              <b-switch
                v-model.trim="articles[k].selected"
                true-value="1"
                false-value="0"
                size="is-small"
              >
                {{ a.name }}
              </b-switch>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="column">
          <b-button class="button" type="button" @click="$parent.close()"
            >Schließen</b-button
          >
          &nbsp;

          <b-button
            class="button"
            type="is-info"
            native-type="submit"
            icon-left="cloud_upload"
            >Kopieren</b-button
          >
        </div>
      </div>
    </form>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators"
export default {
  name: "winesCopyForm",
  props: ["pdata"],

  data() {
    return {
      error: null,
      isLoading: false,
      submitted: false,
      yearList: [],
      articles: []
    }
  },

  computed: {
    data: function() {
      var data = this.pdata
      return data
    },
    typeOfAction: function() {
      if (typeof this.data.id == "undefined" || this.data.id == null) {
        return "Fehler"
      }
      return (
        "Wein Kopieren: #" +
        this.data.id +
        ", " +
        this.data.name_de +
        " Jahrgang " +
        this.data.jahr
      )
    }
  },

  validations: {
    data: {
      jahr: {
        numeric,
        required
      }
    }
  },

  created: function() {
    var list = []
    for (var i = new Date().getFullYear(); i >= 1900; i--) {
      list.push({ key: i, name: i })
    }
    list.push({ key: 0, name: "n.V." })
    this.yearList = list
    //console.log(this.data.articles)
    for (var i = 0; i < this.data.articles.length; i++) {
      this.articles.push({
        selected: 1,
        name:
          this.data.articles[i].flaschen_pro_einheit +
          " x " +
          this.data.articles[i].menge,
        bottles: this.data.articles[i].flaschen_pro_einheit,
        menge: this.data.articles[i].menge
      })
    }
  },

  methods: {
    save: function() {
      //console.log('customer-daten');
      //console.log(this.customer)
      this.error = null
      this.submitted = true
      this.$v.$touch()
      var t = this
      if (t.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        t.$parent.$parent.copy2({
          id: t.data.id,
          jahr: t.data.jahr,
          articles: t.articles
        })
      }
    }
  }
}
</script>
<style>
.switch input[type="checkbox"] + .check {
  display: block !important;
}
</style>
