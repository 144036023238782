<template>
  <tr>
    <td>{{ rating.id }}</td>
    <td>
      <div v-if="inputmode2">
        <b-select v-model.number="rating.bewerter_id" >
          <option
            v-for="option in $utilities.rater"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
        <b-field>
          <b-input v-model="rating.wert" placeholder="Score" class="is-2"></b-input>
        </b-field>
        <b-field>
          <b-input v-model="rating.wert_max" placeholder="Score Max"  class="is-2"></b-input>
        </b-field>
      </div>

      <div v-if="!inputmode2">
        <b>{{ $utilities.getRater(rating.bewerter_id) }}</b
        ><br />
        {{ rating.wert
        }}<span v-if="rating.wert_max > rating.wert"
          >-{{ rating.wert_max }}</span
        >
      </div>
    </td>
    <td>
      <div v-if="inputmode2">
        <b-field>
          <b-input
            v-model="rating.beschreibung"
            placeholder="Text-Bewertung"
            type="textarea"
          ></b-input>
        </b-field>
        <b-field>
          <b-input v-model="rating.quelle" placeholder="Quelle"></b-input>
        </b-field>
        <b-button
          @click.prevent="save()"
          class="button"
          type="is-info"
          native-type="submit"
          icon-left="cloud_upload"
          >Speichern</b-button
        >
      </div>
      <div v-if="!inputmode2">
        <p class="small">{{ rating.beschreibung }}</p>
        <p class="smallgrey">
          Quelle: {{ $utilities.shortenString(rating.quelle, 60) }}
        </p>
      </div>
    </td>
    <td>
      <a title="Bearbeiten" @click.prevent="edit(index)">
        <b-icon icon="square-edit-outline"></b-icon>
      </a>
    </td>
    <td>
      <a title="Löschen" @click.prevent="delete1(rating.id, ' von '+$utilities.getRater(rating.bewerter_id))">
        <b-icon icon="delete_forever"></b-icon>
      </a>
    </td>
  </tr>
</template>

<script>
export default {
  name: "Winerating",

  data() {
    return {
      inputmode2: false
    }
  },

  props: {
    index: { type: [Number, String], required: true },
    rating: { type: Object, required: true },
    inputmode: {type: Boolean, required: true}
  },
   created: function() {
    this.inputmode2 = this.inputmode
  },

  methods: {
    delete1: function(index, name) {
      this.$parent.ratingdelete1(index, name)
    },
    edit: function(index) {
      this.$parent.ratingedit(index)
    },
    save: function(id) {
      this.$parent.ratingsave(this.rating)
    },
    changeInputmode() {
      if (this.inputmode2 == false) {
        this.inputmode2 = true
      } else {
        this.inputmode2 = false
      }
    }
  }
}
</script>

<style>
div.wine-data {
  padding-bottom: 8px;
  margin-bottom: 8px;
}

div.deletelink span.icon {
  color: rgb(230, 20, 20);
}

div.deletelink button.button {
  border-color: rgb(230, 20, 20);
}
</style>
