var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.typeOfAction))])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Artikel-ID"}},[_vm._v(" #"+_vm._s(_vm.$utilities.padLeftWithNull(_vm.data.id))+" ")])],1)]),_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Produzent, Name"}},[(!_vm.wineSelected)?_c('b-autocomplete',{attrs:{"data":_vm.wineList,"placeholder":"Wein auswählen","field":"title","loading":_vm.isFetching},on:{"typing":_vm.getAsyncData,"select":(option) => _vm.setWine(option)},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_vm._v(" "+_vm._s(props.option.producer_name_de)+", "+_vm._s(props.option.wine_name_de)+", "+_vm._s(props.option.jahr == 0 ? "n.V." : props.option.jahr)+", W"+_vm._s(props.option.wine_id)+" ")])])]}}],null,false,2688601137)}):_vm._e(),(_vm.wineSelected)?_c('div',[_vm._v(_vm._s(_vm.wine)+" "+_vm._s(_vm.jahr))]):_vm._e()],1)],1)]),_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Flaschen","type":{ 'is-danger': _vm.$v.data.flaschen_pro_einheit.$error },"message":{
              'Bitte geben Sie die Anzahl der Flaschen an.':
                _vm.submitted && _vm.$v.data.flaschen_pro_einheit.$error,
            }}},[_c('b-select',{model:{value:(_vm.$v.data.flaschen_pro_einheit.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.flaschen_pro_einheit, "$model", _vm._n($$v))},expression:"$v.data.flaschen_pro_einheit.$model"}},_vm._l((_vm.selectBottles),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Flaschengröße","type":{ 'is-danger': _vm.$v.data.menge.$error },"message":{
              'Bitte geben Sie eine Flaschengröße ein.':
                _vm.submitted && _vm.$v.data.menge.$error,
            }}},[_c('b-select',{model:{value:(_vm.$v.data.menge.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.menge, "$model", _vm._n($$v))},expression:"$v.data.menge.$model"}},_vm._l((_vm.bottleSizes),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Lwin18-Code","type":{ 'is-danger': _vm.$v.data.lwin18.$error },"message":{
              'Bitte geben Sie einen gültigen Lwin18-Code an (18 Ziffern).':
                _vm.submitted && _vm.$v.data.lwin18.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.lwin18.$error },model:{value:(_vm.$v.data.lwin18.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.lwin18, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.lwin18.$model"}})],1)],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"column"},[_c('b-button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("Schließen")]),_vm._v("   "),_c('b-button',{staticClass:"button",attrs:{"type":"is-info","native-type":"submit","icon-left":"cloud_upload"}},[_vm._v("Speichern")])],1)])]),_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading,"can-cancel":false},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }