var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.typeOfAction))])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns view-columns is-multiline"},[_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"ID"}},[_vm._v(" #"+_vm._s(_vm.$utilities.padLeftWithNull(_vm.data.id))+" ")])],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Name deutsch","type":{ 'is-danger': _vm.$v.data.name_de.$error },"message":{
              'Bitte geben Sie einen deutschen Namen an.':
                _vm.submitted && _vm.$v.data.name_de.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.name_de.$error },model:{value:(_vm.$v.data.name_de.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.name_de, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.name_de.$model"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Name englisch","type":{ 'is-danger': _vm.$v.data.name_en.$error },"message":{
              'Bitte geben Sie einen englischen Namen an.':
                _vm.submitted && _vm.$v.data.name_en.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.name_en.$error },model:{value:(_vm.$v.data.name_en.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.name_en, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.name_en.$model"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Code","type":{ 'is-danger': _vm.$v.data.code.$error },"message":{
              'Bitte geben Sie den zweistelligen Ländercode an.':
                _vm.submitted && _vm.$v.data.code.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.code.$error },model:{value:(_vm.$v.data.code.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.code, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.code.$model"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Registrierung","type":{ 'is-danger': _vm.$v.data.can_order.$error },"message":{
              '': _vm.submitted && _vm.$v.data.can_order.$error,
            }}},[_vm._v(" Erlaubt: "),_c('b-switch',{attrs:{"true-value":"1","false-value":"0","size":"is-small"},model:{value:(_vm.$v.data.can_order.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.can_order, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.can_order.$model"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Umsatzsteuerregelung","type":{ 'is-danger': _vm.$v.data.vat_rule.$error },"message":{
              'Bitte geben Sie die Anzahl der Flaschen an.':
                _vm.submitted && _vm.$v.data.vat_rule.$error,
            }}},[_c('b-select',{model:{value:(_vm.$v.data.vat_rule.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.vat_rule, "$model", _vm._n($$v))},expression:"$v.data.vat_rule.$model"}},_vm._l((_vm.vatruleOptions),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Versandkostengruppe","type":{ 'is-danger': _vm.$v.data.shipping_region_id.$error },"message":{
              'Bitte geben Sie die Anzahl der Flaschen an.':
                _vm.submitted && _vm.$v.data.shipping_region_id.$error,
            }}},[_c('b-select',{model:{value:(_vm.$v.data.shipping_region_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.shipping_region_id, "$model", _vm._n($$v))},expression:"$v.data.shipping_region_id.$model"}},_vm._l((_vm.shippingregionOptions),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name_de)+" ")])}),0)],1)],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"column"},[_c('b-button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("Schließen")]),_vm._v("   "),_c('b-button',{staticClass:"button",attrs:{"type":"is-info","icon-left":"cloud_upload"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.save.apply(null, arguments)}}},[_vm._v("Speichern")])],1)])]),_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading,"can-cancel":false},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }