<template>
  <b-table
    :data="data.data"
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    :range-before="5"
    :range-after="5"
    paginated
    backend-pagination
    pagination-position="both"
    :total="data.count"
    :per-page="data.perpage"
    @page-change="onPageChange"
    :current-page.sync="data.page"
    aria-next-label="Weiter"
    aria-previous-label="Zurück"
    aria-page-label=" Seite "
    aria-current-label="Aktuelle Seite"
    backend-sorting
    :default-sort="[data.sortField, data.sortOrder]"
    @sort="onSort"
    icon-pack="icm"
    :mobile-cards="false"
  >
    <template slot="top-left">
      <div>
        <b-field grouped>
          <b-field class="control has-icons-right">
            <input
              class="input"
              placeholder="Suche"
              v-model="searchphrase"
              v-on:keyup.enter="list()"
            />
            <span class="icon is-right" @click="list()" id="searchbutton">
              <i class="icm icm-search"></i>
            </span>
          </b-field>
          <b-field class="control">
            <a :to="add" @click="add()" type="is-link" class="navbar-link is-arrowless"
              ><b-icon icon="plus1"></b-icon
            ></a>
          </b-field>
        </b-field>
      </div>
    </template>

    <b-table-column v-slot="props" field="Country.id" label="Id" numeric sortable>
      {{ $utilities.padLeftWithNull(props.row.id) }}
    </b-table-column>

    <b-table-column v-slot="props" field="Country.name_de" label="Name" sortable>
      {{ props.row.name_de }}
    </b-table-column>

    <b-table-column v-slot="props" field="Country.code" label="Code" sortable>
      {{ props.row.code }}
    </b-table-column>

    <b-table-column v-slot="props" field="Country.can_order" label="Registrierung">
      {{ props.row.can_order_on_rp == 1 ? "ja" : "nein" }}
    </b-table-column>

    <b-table-column v-slot="props" field="Country.name_de" label="Ust.">
      {{
        props.row.vat_rule == 1
          ? "USt."
          : props.row.vat_rule == 2
          ? "USt. ohne VAT-ID"
          : "keine Ust."
      }}
    </b-table-column>

    <b-table-column v-slot="props" label="Versandkosten">
      {{ getShippinregionName(props.row.shipping_region_id) }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="A"
      class="iconlink"
      field="link1"
      header-class="iconlink"
    >
      <a title="Ansehen" @click.prevent="view(props.row.id)">
        <b-icon icon="find_in_page"></b-icon>
      </a>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="B"
      class="editlink"
      field="link2"
      header-class="iconlink"
    >
      <a title="Bearbeiten" @click.prevent="edit(props.row.id)">
        <b-icon icon="square-edit-outline"></b-icon>
      </a>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="L"
      class="editlink"
      field="link2"
      header-class="iconlink"
    >
      <a title="Löschen" @click.prevent="delete1(props.row.id, props.row.name_de)">
        <b-icon icon="delete_forever"></b-icon>
      </a>
    </b-table-column>
  </b-table>
</template>

<script>
import View from "./countriesview.vue";
import Form from "./countriesform.vue";
export default {
  name: "countriesList",
  data() {
    return {
      apiControllerName: "countries",
      loading: {},
      data: {
        count: 0,
        perpage: 50,
        data: [],
        page: 1,
        order: { field: "name", direction: "ASC" },
        shippingregionOptions: [],
      },

      error: "",
      subtitle: "",
      isLoading: true,
      isReady: false,
      searchphrase: "",
      modal: false,
    };
  },

  mounted: function () {
    this.$store.commit("setHeadline", {
      subheadline: "Länder",
    });

    this.list();
    this.isReady = true;
  },
  methods: {
    list: async function (data = null) {
      var t = this;
      t.isLoading = true;
      t.$http
        .post("/" + t.apiControllerName, {
          page: t.data.page,
          search: t.searchphrase,
          order: t.data.order,
        })
        .then(function (resp) {
          t.data = resp.data.data;

          t.isLoading = false;
          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
          t.isLoading = false;
        });
    },
    onPageChange(page) {
      this.data.page = page;
      this.list();
    },
    /*
     * Handle sort event
     */
    onSort(field, direction) {
      //console.log(field + ' » ' + direction)

      this.data.order = { field, direction };
      this.list();
    },
    view: function (id) {
      var t = this;

      t.$http({
        url: "/" + t.apiControllerName + "/view/" + id,
        method: "get",
      })
        .then(function (resp) {
          //console.log( resp.data.data);

          t.modal = t.$buefy.modal.open({
            parent: t,
            component: View,
            hasModalCard: false,
            width: 1250,
            props: {
              data: resp.data.data,
              shippingregionOptions: t.data.shippingregionOptions,
            },
          });

          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
        });
    },
    edit: function (id) {
      var t = this;

      //this.$router.replace('/users/edit/' + id)

      t.$http({
        url: "/" + t.apiControllerName + "/view/" + id,
        method: "get",
      })
        .then(function (resp) {
          //console.log( resp.data.data);

          t.modal = t.$buefy.modal.open({
            parent: t,
            component: Form,
            hasModalCard: false,
            width: 1250,
            props: {
              pdata: resp.data.data,
              shippingregionOptions: t.data.shippingregionOptions,
            },
          });

          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
        });
    },

    add: function () {
      var t = this;
      var pdata = {
        name_de: "",
        name_en: "",
        code: "",
        can_order_on_rp: 0,
      };

      t.modal = t.$buefy.modal.open({
        parent: t,
        component: Form,
        hasModalCard: false,
        scroll: "keep",
        props: { pdata: pdata,shippingregionOptions: t.data.shippingregionOptions },
      });
    },

    delete1: function (id, name) {
      var t = this;
      t.modal = this.$buefy.dialog.confirm({
        title: "Bitte bestätigen!",
        message: "Wollen Sie Datensatz #" + id + ' "' + name + '" wirklich löschen?',
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.delete2(id),
      });
    },
    delete2: function (id) {
      //console.log('delete '+id);
      var t = this;
      this.$http({
        url: "/" + t.apiControllerName + "/delete/" + id,
        method: "get",
      })
        .then(function (resp) {
          t.list(resp.data.data);
          t.modal.close();
          //console.log(resp.data)
          return true;
        })
        .catch(function (error) {
          //console.log('Shithappens')
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            this.$buefy.toast.open({
              message: error.response.data.message,
              type: "is-danger",
              duration: 5000,
            });
          } else {
            this.$buefy.toast.open({
              message:
                "Server (API) nicht erreichbar. Bitte versuchen Sie es später erneut.",
              type: "is-danger",
              duration: 5000,
            });
          }
        });
    },
    async save(formdata) {
      //console.log('speichern3')
      this.isLoading = true;
      var t = this;
      t.$http
        .post("/" + t.apiControllerName + "/save", { data: formdata })
        .then(function (resp) {
          t.isLoading = false;

          t.list(resp.data.data);
          t.modal.close();
          return true;
        })
        .catch(function (error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
          t.isLoading = false;
          return false;
        });
      return false;
    },
    getShippinregionName (id)
    {
      if (this.data.shippingregionOptions.length == 0)
      {
        return 'Regelliste fehlt';
      }

      if (id == 0)
      {
        return 'UPS';
      }

      for (var i = 0; i < this.data.shippingregionOptions.length; i++)
      {
        if (this.data.shippingregionOptions[i].id == id)
        {
          return this.data.shippingregionOptions[i].name_de
        }
      }
      return 'nicht zugeordnet';
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#searchbutton {
  pointer-events: all !important;
  cursor: pointer !important;
}
</style>
