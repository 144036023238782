var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"card-header-title"},[_vm._v("#"+_vm._s(_vm.data.id))])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('label',[_vm._v("Artikel-ID")]),_vm._v(" #"+_vm._s(_vm.$utilities.padLeftWithNull(_vm.data.id))+" ")]),_c('div',{staticClass:"column"},[_c('label',[_vm._v("Produzent, Name")]),_vm._v(" "+_vm._s(_vm.data.wine.producer.producer_name_de)+", "+_vm._s(_vm.data.wine.name_de)+" ")]),_c('div',{staticClass:"column"},[_c('label',[_vm._v("Jahrgang")]),_vm._v(" "+_vm._s(_vm.data.wine.jahr == 0 ? "n.V." : _vm.data.wine.jahr)+" ")])]),_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('label',[_vm._v("Flaschen")]),_vm._v(" "+_vm._s(_vm.data.flaschen_pro_einheit)+" ")]),_c('div',{staticClass:"column"},[_c('label',[_vm._v("Flaschengröße")]),_vm._v(" "+_vm._s(_vm.data.menge)+" L ")]),_c('div',{staticClass:"column"},[_c('label',[_vm._v("Lwin18-Code")]),_vm._v(" "+_vm._s(_vm.data.lwin18)+" ")])]),_c('b-button',{staticClass:"button",attrs:{"type":"is-info","icon-left":"square-edit-outline"},on:{"click":function($event){return _vm.edit(_vm.data.id)}}},[_vm._v("Bearbeiten")]),_vm._v("   "),_c('b-button',{staticClass:"button",attrs:{"type":"is-danger","icon-left":"delete_forever"},on:{"click":function($event){return _vm.delete1(
          _vm.data.id,
          _vm.data.wine.name_de +
            ' ' +
            _vm.data.wine.jahr +
            ': ' +
            _vm.data.flaschen_pro_einheit +
            ' x ' +
            _vm.data.menge
        )}}},[_vm._v("Löschen")])],1),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"column"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v(" Schließen ")]),_vm._v("   ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }