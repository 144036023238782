<template>
  <div class="card">
    <form method="post" @submit.prevent="save">
      <div class="card-header">
        <h1 class="card-header-title">{{ typeOfAction }}</h1>
      </div>
      <div class="card-content">
        <div class="columns view-columns is-multiline">
          <div class="column is-3">
            <b-field label="Wein-ID">
              #{{ $utilities.padLeftWithNull(data.id) }}
            </b-field>
          </div>

          <div class="column is-3">
            <b-field label="Region">
              <b-autocomplete
                v-if="!regionSelected"
                v-model="region"
                :data="filteredRegion()"
                placeholder="z.B. Bordeaux"
                clearable
                @select="(option) => setRegion(option)"
              >
                <template slot="empty">Nichts gefunden</template>
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">
                      {{ props.option.name_de }}
                    </div>
                  </div>
                </template>
              </b-autocomplete>
              <div v-if="regionSelected">{{ region }}</div>
            </b-field>
          </div>

          <div class="column is-3">
            <b-field label="Produzent">
              <b-autocomplete
                v-if="!producerSelected"
                v-model="producer"
                :data="filteredProducer()"
                placeholder="z.B. Mouton"
                clearable
                @select="(option) => setProducer(option)"
              >
                <template slot="empty">Nichts gefunden</template>
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">
                      {{ props.option.name_de }}
                    </div>
                  </div>
                </template>
              </b-autocomplete>
              <div v-if="producerSelected">{{ producer }}</div>
            </b-field>
          </div>

          <div class="column is-3">
            <b-field
              label="Typ"
              :type="{ 'is-danger': $v.data.typ.$error }"
              :message="{
                'Bitte wählen Sie eineb Typ.': submitted && $v.data.typ.$error,
              }"
            >
              <b-select
                placeholder="Bitte wählen Sie einen Typ"
                v-model.trim="$v.data.typ.$model"
                :class="{ is_danger: $v.data.typ.$error }"
                style="width: inherit"
              >
                <option
                  v-for="option in $utilities.typ"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="column is-6">
            <b-field
              label="Name deutsch"
              :type="{ 'is-danger': $v.data.name_de.$error }"
              :message="{
                'Bitte geben Sie einen deutschen Namen an.':
                  submitted && $v.data.name_de.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.name_de.$model"
                :class="{ is_danger: $v.data.name_de.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column is-6">
            <b-field
              label="Name englisch"
              :type="{ 'is-danger': $v.data.name_en.$error }"
              :message="{
                'Bitte geben Sie einen englischen Namen an.':
                  submitted && $v.data.name_en.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.name_en.$model"
                :class="{ is_danger: $v.data.name_en.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column is-6">
            <b-field
              label="Jahrgang"
              :type="{ 'is-danger': $v.data.jahr.$error }"
              :message="{
                'Bitte wählen Sie ein Jahr.': submitted && $v.data.jahr.$error,
              }"
            >
              <b-select
                expanded
                placeholder="Bitte wählen Sie ein Jahr"
                v-model.trim="$v.data.jahr.$model"
                :class="{ is_danger: $v.data.jahr.$error }"
                style="width: inherit"
              >
                <option v-for="option in yearList" :value="option.key" :key="option.key">
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="column is-3">
            <b-field
              label="EAN"
              :type="{ 'is-danger': $v.data.ean.$error }"
              :message="{
                'Bitte geben Sie einen EAN-Code an.': submitted && $v.data.ean.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.ean.$model"
                :class="{ is_danger: $v.data.ean.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column is-3">
            <b-field
              label="Artnr. Produzent"
              :type="{ 'is-danger': $v.data.artnr_produzent.$error }"
              :message="{
                'Bitte geben Sie eine ArtNr. ein.':
                  submitted && $v.data.artnr_produzent.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.artnr_produzent.$model"
                :class="{ is_danger: $v.data.artnr_produzent.$error }"
              >
              </b-input>
            </b-field>
          </div>


        <div class="column is-12">
            <b-field
              label="Bild"
             
            >
              <img v-if="data.image != false" :src="getImage(data.image)" style="max-height: 200px;" alt="" />
              <br v-if="data.image != false">
              <a v-if="data.image != false" href="#" @click.prevent="deleteImage()">Dieses Bild löschen</a>
              <b-field class="file">
      <b-upload
      v-if="data.image == false"
        v-model="file"
        :loading="isUploading"
        @input="getAsyncData"
        accept=".jpg,.png,.webp,.jpeg"
        multiple
      >
        <a class="button is-default">
          <b-icon icon="cloud_upload"></b-icon>
        </a>
      </b-upload>
      <b-loading
        :is-full-page="false"
        :active.sync="isUploading"
        :can-cancel="false"
        loading-icon-size="2rem"
      ></b-loading>
    </b-field>
             
            </b-field>
          </div>


          <div class="column is-3">
            <b-field
              label="Nährwerte Brennwert in KJ"
              :type="{ 'is-danger': $v.data.nut_brennwert.$error }"
              :message="{
                'Bitte geben Sie den Brennwert ein.':
                  submitted && $v.data.nut_brennwert.$error,
              }"
            >
              <b-input
              @input="checkFormat('nut_brennwert')"
                v-model.trim="$v.data.nut_brennwert.$model"
                :class="{ is_danger: $v.data.nut_brennwert.$error }"
                class="has-text-right"
                
              >
              </b-input>
              
            </b-field>
          </div>

          <div class="column is-3">
            <b-field
              label="Nährwerte Alkohol in %"
              :type="{ 'is-danger': $v.data.nut_alkohol.$error }"
              :message="{
                'Bitte geben Sie den Alkoholgehalt ein.':
                  submitted && $v.data.nut_alkohol.$error,
              }"
            >
              <b-input
              @input="checkFormat('nut_alkohol')"
                v-model.trim="$v.data.nut_alkohol.$model"
                :class="{ is_danger: $v.data.nut_alkohol.$error }"
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field
              label="Nährwerte Kohlenhydrate in g"
              :type="{ 'is-danger': $v.data.nut_kohlenhydrate.$error }"
              :message="{
                'Bitte geben Sie die Kohlenhydrate ein.':
                  submitted && $v.data.nut_kohlenhydrate.$error,
              }"
            >
              <b-input
              @input="checkFormat('nut_kohlenhydrate')"
                v-model.trim="$v.data.nut_kohlenhydrate.$model"
                :class="{ is_danger: $v.data.nut_kohlenhydrate.$error }"
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field
              label="Nährwerte Zucker in g"
              :type="{ 'is-danger': $v.data.nut_zucker.$error }"
              :message="{
                'Bitte geben Sie den Zuckeranteil ein.':
                  submitted && $v.data.nut_zucker.$error,
              }"
            >
              <b-input
              @input="checkFormat('nut_zucker')"
                v-model.trim="$v.data.nut_zucker.$model"
                :class="{ is_danger: $v.data.nut_zucker.$error }"
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              label="Nährwerte Liste Inhaltsstoffe deutsch"
              :type="{ 'is-danger': $v.data.nut_inhaltsstoffe_de.$error }"
              :message="{
                'Bitte geben Sie die Inhaltsstoffe ein.':
                  submitted && $v.data.nut_inhaltsstoffe_de.$error,
              }"
            >
              <b-input

                v-model.trim="$v.data.nut_inhaltsstoffe_de.$model"
                :class="{ is_danger: $v.data.nut_inhaltsstoffe_de.$error }"
              >
              </b-input>
            </b-field>
          </div>
          
          <div class="column is-6">
            <b-field
              label="Nährwerte Liste Inhaltsstoffe englisch"
              :type="{ 'is-danger': $v.data.nut_inhaltsstoffe_en.$error }"
              :message="{
                'Bitte geben Sie die Inhaltsstoffe ein.':
                  submitted && $v.data.nut_inhaltsstoffe_en.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.nut_inhaltsstoffe_en.$model"
                :class="{ is_danger: $v.data.nut_inhaltsstoffe_en.$error }"
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              label="Nährwerte Liste Allergene deutsch"
              :type="{ 'is-danger': $v.data.nut_allergene_de.$error }"
              :message="{
                'Bitte geben Sie die Allergene ein.':
                  submitted && $v.data.nut_allergene_de.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.nut_allergene_de.$model"
                :class="{ is_danger: $v.data.nut_allergene_de.$error }"
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              label="Nährwerte Liste Allergene englisch"
              :type="{ 'is-danger': $v.data.nut_allergene_en.$error }"
              :message="{
                'Bitte geben Sie die Allergene ein.':
                  submitted && $v.data.nut_allergene_en.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.nut_allergene_en.$model"
                :class="{ is_danger: $v.data.nut_allergene_en.$error }"
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-12">
            <b-field
              label="Nährwerte externer Link"
              :type="{ 'is-danger': $v.data.nut_link.$error }"
              :message="{
                'Bitte geben Sie die Allergene ein.':
                  submitted && $v.data.nut_link.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.nut_link.$model"
                :class="{ is_danger: $v.data.nut_link.$error }"
              >
              </b-input>
            </b-field>
          </div>
         
        </div>
      </div>
      <div class="card-footer">
        <div class="column">
          <b-button class="button" type="button" @click="$parent.close()"
            >Schließen</b-button
          >
          &nbsp;

          <b-button
            class="button"
            type="is-info"
            native-type="submit"
            icon-left="cloud_upload"
            >Speichern</b-button
          >
        </div>
      </div>
    </form>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>

import ImageUpload from '../_helpers/wineimageupload.vue';
import { required, minLength, maxLength, numeric, decimal } from "vuelidate/lib/validators";
export default {
  name: "winesForm",
  props: ["pdata"],
  components: { ImageUpload},

  data() {
    return {
      file: null,
      isUploading: false,
      data: {},
      error: null,
      isLoading: false,
      producer: "",
      producerSelected: false,
      region: "",
      regionSelected: false,
      submitted: false,
      yearList: [],
    };
  },

  computed: {
    
    typeOfAction: function () {
      if (typeof this.data.id == "undefined" || this.data.id == null) {
        return "Neuen Wein anlegen";
      }
      return "Wein bearbeiten: #" + this.data.id + " " + this.data.name_de;
    },
  },

  validations: {
    data: {
      name_de: {
        required,
      },
      name_en: {
        required,
      },
      jahr: {
        numeric,
        required,
      },
      producer_id: { numeric, required },
      region_id: { numeric, required },
      ean: {},
      typ: {},
      artnr_produzent: {},
      nut_brennwert: { numeric },
      nut_alkohol: { decimal },
      nut_kohlenhydrate: { decimal },
      nut_zucker: { decimal },
      nut_inhaltsstoffe_de: {},
      nut_inhaltsstoffe_en: {},
      nut_allergene_de: {},
      nut_allergene_en: {},
      nut_link: {},
    },
  },

  created: function () {
    this.data = this.pdata;
    var list = [];
    for (var i = new Date().getFullYear(); i >= 1900; i--) {
      list.push({ key: i, name: i });
    }
    list.push({ key: 0, name: "n.V." });
    this.yearList = list;

    if (typeof this.data.id == "undefined" || this.data.id == null) {
      this.producerSelected = false;
      this.regionSelected = false;
    } else {
      this.producerSelected = true;
      this.regionSelected = true;
      this.producer = this.$parent.$parent.getProducer(this.data.producer_id);
      this.region = this.$parent.$parent.getRegion(this.data.region_id);
    }
  },

  methods: {
    save: function () {
      //console.log('customer-daten');
      //console.log(this.customer)
      this.error = null;
      this.submitted = true;
      this.$v.$touch();
      var t = this;
      if (t.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        t.$parent.$parent.save(t.data);
      }
    },
    filteredProducer() {
      var t = this;
      return this.$parent.$parent.producerList.filter(function (p) {
        return (
          p.name_de
            .toString()
            .toLowerCase()
            .indexOf(t.producer.toString().toLowerCase()) >= 0
        );
      });
    },
    setProducer(option) {
      this.producerSelected = true;
      this.producer = option.name_de;
      this.producer_id = option.id;
      this.data.producer_id = option.id;
    },

    filteredRegion() {
      //console.log(this.producer)
      var t = this;
      return this.$parent.$parent.regionList.filter(function (p) {
        return (
          p.name_de.toString().toLowerCase().indexOf(t.region.toString().toLowerCase()) >=
          0
        );
      });
    },
    setRegion(option) {
      this.regionSelected = true;
      this.region = option.name_de;
      this.region_id = option.id;
      this.data.region_id = option.id;
    },
    checkFormat (k)
    {
      //console.log(this.$v.data[k].$model);
      var v = this.$v.data[k].$model;
      //console.log('Check ' + v);
      v = parseFloat(v.replace(',', '.'));
      //console.log('Converted to ' + v);
      this.$v.data[k].$reset();
      this.$v.data[k].$model = parseFloat(v);
      this.$v.data[k].$reset();
      
    },
    getImage(image) {
      if (image != false) {
        return this.$store.getters.config.page.imageBaseUrl+"/" + image;
      } else {
        return false;
      }
    },
    deleteImage () {
      this.data.image = false;
      this.data.image_delete = true;
    },
    
    getAsyncData: async function (file) {
      if (file == "" || file.length == 0) {
        return
      }
      //console.log(file)

      var t = this
      this.isUploading = true
      t.uploadResults = []
      
      for (var i = 0; i < file.length; i++) {
        //console.log(file[i])
        t.uploadResults[i] = {name: file[i].name, servername: false}
        t.uploadResults[i].servername = await t.uploadImage(file[i],  i)
        //console.log('fertig ' + i );
      }
      //console.log(t.uploadResults)

      t.file = null;  
    },
    uploadImage: async function (file, index) {
      var t = this
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async function () {
        //console.log("poste " + index)
        return await t.$http
          .post(
            "/wines/imageupload",
            { image: reader.result },
            { timeout: 20000 }
          )
          .then(async function (resp) {
           // console.log("result " + index + " " + resp.data.data)
            t.data.image = "/tmpuploads/" + resp.data.data + '_b.jpg'
            t.data.image_save = resp.data.data;
           

            return "/tmpuploads/" + resp.data.data
          })
          .catch((error) => {
            throw error
          })
          .finally(() => {
            t.isUploading = false
          })
      }
      reader.onerror = function (error) {
        //console.log("Error: ", error)
      }
    },
   
  },
};
</script>
<style></style>
