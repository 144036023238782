<template>
  <div class="card">
    <form method="post" @submit.prevent="save">
      <div class="card-header">
        <h1 class="card-header-title">{{ typeOfAction }}</h1>
      </div>
      <div class="card-content">
        <div class="columns view-columns">
          <div class="column">
            <b-field label="Kundennummer">
              #{{ $utilities.padLeftWithNull(data.id) }}
            </b-field>
          </div>

          <div class="column">
            <b-field
              label="Name deutsch"
              :type="{ 'is-danger': $v.data.name_de.$error }"
              :message="{
                'Bitte geben Sie einen deutschen Namen an.':
                  submitted && $v.data.name_de.$error
              }"
            >
              <b-input
                v-model.trim="$v.data.name_de.$model"
                :class="{ is_danger: $v.data.name_de.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column">
            <b-field
              label="Name englisch"
              :type="{ 'is-danger': $v.data.name_en.$error }"
              :message="{
                'Bitte geben Sie einen englischen Namen an.':
                  submitted && $v.data.name_en.$error
              }"
            >
              <b-input
                v-model.trim="$v.data.name_en.$model"
                :class="{ is_danger: $v.data.name_en.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column">
            <b-field
              label="Land"
              :type="{ 'is-danger': $v.data.country_id.$error }"
              :message="{
                'Bitte wählen Sie ein Land.':
                  submitted && $v.data.country_id.$error
              }"
            >
              <b-select
                placeholder="Bitte wählen Sie ein Land"
                v-model.trim="$v.data.country_id.$model"
                :class="{ is_danger: $v.data.country_id.$error }"
                style="width:inherit"
              >
                <option
                  v-for="(name, key) in $parent.$parent.countryList"
                  :value="key"
                  :key="key"
                >
                  {{ name }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="column">
            <b-field
              label="Sortierziffer"
              :type="{ 'is-danger': $v.data.sortno.$error }"
              :message="{
                'Bitte geben Sie eine Sortierziffer an.':
                  submitted && $v.data.sortno.$error
              }"
            >
              <b-input
                v-model.trim="$v.data.sortno.$model"
                :class="{ is_danger: $v.data.sortno.$error }"
              >
              </b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="column">
          <b-button class="button" type="button" @click="$parent.close()"
            >Schließen</b-button
          >
          &nbsp;

          <b-button
            class="button"
            type="is-info"
            native-type="submit"
            icon-left="cloud_upload"
            >Speichern</b-button
          >
        </div>
      </div>
    </form>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  numeric
} from "vuelidate/lib/validators"
export default {
  name: "countriesForm",
  props: ["pdata"],

  data() {
    return {
      data: this.pdata,
      error: null,
      isLoading: false,
      submitted: false
    }
  },

  computed: {
    typeOfAction: function() {
      if (typeof this.data.id == "undefined" || this.data.id == null) {
        return "Neuen Produzent anlegen"
      }
      return "Prouzent bearbeiten: #" + this.data.id + " " + this.data.name_de
    }
  },
  validations: {
    data: {
      name_de: {
        minLength: minLength(3)
      },
      name_en: {
        minLength: minLength(3)
      },
      country_id: {
        numeric,
        required
      },

      sortno: { numeric, required }
    }
  },

  methods: {
    save: function() {
      //console.log('customer-daten');
      //console.log(this.customer)
      this.error = null
      this.submitted = true
      this.$v.$touch()
      var t = this
      if (t.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        t.$parent.$parent.save(t.data)
      }
    }
  }
}
</script>
<style></style>
