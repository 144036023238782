<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">#{{ data.id }} {{ data.name_de }}</h1>
    </div>
    <div class="card-content">
      <div class="columns view-columns">
        <div class="column">
          <label>ID</label>
          #{{ $utilities.padLeftWithNull(data.id) }}
          </div>
        <div class="column">
          <label>Name deutsch</label>
          {{ data.name_de }}
        </div>
        <div class="column">
          <label>Name englisch</label>
          {{ data.name_en }}

        </div>
        <div class="column">
          <label>Land</label>
          {{ $parent.$parent.getCountry(data.country_id) }}

        </div>
        <div class="column">
          <label>Sortierung</label>
          {{ data.sortno }}

        </div>
        
      </div>


      <b-button
        class="button"
        type="is-info"
        @click="edit(data.id)"
        icon-left="square-edit-outline"
        >Bearbeiten</b-button
      >

      &nbsp;

      <b-button
        class="button"
        type="is-danger"
        icon-left="delete_forever"
        @click="delete1(data.id, data.name_de)"
        >Löschen</b-button
      >
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "countriesView",
  props: ["data"],
  

  methods: {
    delete1: function(id, name) {
      this.$parent.close()
      this.$parent.$parent.delete1(id, name)
    },
    edit: function(id) {
      this.$parent.close()
      this.$parent.$parent.edit(id)
    },
  
  }
}
</script>
