<template>
  <div class="card">
    <form method="post" @submit.prevent="save">
      <div class="card-header">
        <h1 class="card-header-title">{{ typeOfAction }}</h1>
      </div>
      <div class="card-content">
        <div class="columns view-columns is-multiline">
          <div class="column is-4">
            <b-field label="ID"> #{{ $utilities.padLeftWithNull(data.id) }} </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Name deutsch"
              :type="{ 'is-danger': $v.data.name_de.$error }"
              :message="{
                'Bitte geben Sie einen deutschen Namen an.':
                  submitted && $v.data.name_de.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.name_de.$model"
                :class="{ is_danger: $v.data.name_de.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Name englisch"
              :type="{ 'is-danger': $v.data.name_en.$error }"
              :message="{
                'Bitte geben Sie einen englischen Namen an.':
                  submitted && $v.data.name_en.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.name_en.$model"
                :class="{ is_danger: $v.data.name_en.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Code"
              :type="{ 'is-danger': $v.data.code.$error }"
              :message="{
                'Bitte geben Sie den zweistelligen Ländercode an.':
                  submitted && $v.data.code.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.code.$model"
                :class="{ is_danger: $v.data.code.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Registrierung"
              :type="{ 'is-danger': $v.data.can_order.$error }"
              :message="{
                '': submitted && $v.data.can_order.$error,
              }"
            >
              Erlaubt:
              <b-switch
                v-model.trim="$v.data.can_order.$model"
                true-value="1"
                false-value="0"
                size="is-small"
              >
              </b-switch>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Umsatzsteuerregelung"
              :type="{ 'is-danger': $v.data.vat_rule.$error }"
              :message="{
                'Bitte geben Sie die Anzahl der Flaschen an.':
                  submitted && $v.data.vat_rule.$error,
              }"
            >
              <b-select v-model.number="$v.data.vat_rule.$model">
                <option
                  v-for="option in vatruleOptions"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Versandkostengruppe"
              :type="{ 'is-danger': $v.data.shipping_region_id.$error }"
              :message="{
                'Bitte geben Sie die Anzahl der Flaschen an.':
                  submitted && $v.data.shipping_region_id.$error,
              }"
            >
              <b-select v-model.number="$v.data.shipping_region_id.$model">
                <option
                  v-for="option in shippingregionOptions"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name_de }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="column">
          <b-button class="button" type="button" @click="$parent.close()"
            >Schließen</b-button
          >
          &nbsp;

          <b-button
            class="button"
            type="is-info"
            icon-left="cloud_upload"
             @click.prevent.stop="save"
            >Speichern</b-button
          >
        </div>
      </div>
    </form>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  numeric
} from "vuelidate/lib/validators"
const valTelephone = (value) => /^\+[0-9]{6,20}$/.test(value)
export default {
  name: "countriesForm",
  props: ["pdata", "shippingregionOptions"],

  data() {
    return {
      data: this.pdata,
      error: null,
      isLoading: false,
      submitted: false,
      vatruleOptions: [
        { id: 0, name: "Keine USt. berechnen" },
        { id: 1, name: "immer USt. berechnen" },
        { id: 2, name: "USt. berechnen, wenn keine VAT-ID" }
      ],
      
    }
  },

  computed: {
    typeOfAction: function() {
      if (typeof this.data.id == "undefined" || this.data.id == null) {
        return "Neues Land anlegen"
      }
      return "Land bearbeiten: #" + this.data.id + " " + this.data.name_de
    }
  },
  validations: {
    data: {
      name_de: {
        minLength: minLength(3)
      },
      name_en: {
        minLength: minLength(3)
      },
      code: {
        minLength: minLength(2),
        maxLength: maxLength(2)
      },

      can_order: { required },
      vat_rule: { required },
      shipping_region_id:{required}
    }
  },

  methods: {
    save: function() {
      //console.log('customer-daten');
      //console.log(this.data)
      this.error = null
      this.submitted = true
      this.$v.$touch()
      var t = this
      if (t.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        t.$parent.$parent.save(t.data)
      }
    }
  }
}
</script>
<style></style>
