<template>
  <div class="imagebox column is-1 py-0">
    <b-field class="file">
      <b-upload
        v-model="file"
        :loading="isUploading"
        @input="getAsyncData"
        accept=".jpg,.png,.webp,.jpeg"
        multiple
      >
        <a class="button is-default">
          <b-icon icon="cloud_upload"></b-icon>
        </a>
      </b-upload>
      <b-loading
        :is-full-page="false"
        :active.sync="isUploading"
        :can-cancel="false"
        loading-icon-size="2rem"
      ></b-loading>
    </b-field>
  </div>
</template>

<script>
import debounce from "lodash/debounce"
export default {
  name: "ImageUpload",
  data() {
    return {
      file: null,
      isUploading: false,
      uploadResults: []
    }
  },

  methods: {
    getAsyncData: async function (file) {
      if (file == "" || file.length == 0) {
        return
      }
      //console.log(file)

      var t = this
      this.isUploading = true
      t.uploadResults = []
      
      for (var i = 0; i < file.length; i++) {
        //console.log(file[i])
        t.uploadResults[i] = {name: file[i].name, servername: false}
        t.uploadResults[i].servername = await t.uploadImage(file[i],  i)
        //console.log('fertig ' + i );
      }
      //console.log(t.uploadResults)

      t.file = null;  
    },
    uploadImage: async function (file, index) {
      var t = this
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async function () {
        //console.log("poste " + index)
        return await t.$http
          .post(
            "/consignments/imageupload",
            { image: reader.result },
            { timeout: 20000 }
          )
          .then(async function (resp) {
           // console.log("result " + index + " " + resp.data.data)
            t.uploadResults[index].servername = "/tmpuploads/" + resp.data.data
            t.pushImages()

            return "/tmpuploads/" + resp.data.data
          })
          .catch((error) => {
            throw error
          })
          .finally(() => {
            t.isUploading = false
          })
      }
      reader.onerror = function (error) {
        //console.log("Error: ", error)
      }
    },
    pushImages: function () {
      var t = this
      var allUploadsDone = true
      for (var i = 0; i < t.uploadResults.length; i++) {
        if (
          t.uploadResults[i].servername == false ||
          typeof t.uploadResults[i].servername == "undefined"
        ) {
          allUploadsDone = false
          //console.log("Not ready yet")
          break
        }
      }
      if (allUploadsDone == true) {
        //console.log("ready yet")
        for (var i = t.uploadResults.length - 1; i >= 0; i--) {
         // console.log(t.uploadResults[i]);
          t.$parent.addImage(t.uploadResults[i].servername, i)
        }
      }
    }
  }
}
</script>

<style scoped>
div.preview a.deletelink {
  display: block;
  position: relative;
  margin-top: -30px;
  margin-bottom: 0px;
  right: auto;
  left: auto;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: rgba(230, 20, 20, 0.7);
  color: #ffffff;
  z-index: 1000;
}

div.loading-icon {
  font-size: 2rem;
}
</style>
